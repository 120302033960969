import Cookie from 'js-cookie';
import queryString from 'query-string';
import * as Sentry from '@sentry/react';
import { InvalidTokenError } from 'jwt-decode';

import { ROLES, ROLE_PERMISSIONS } from '_js/constants/Roles';

import { filterUnique } from '_js/utils/arrayUtilities';
import { decodeJwtPayload } from '_js/utils/api/jwt';

import { MissingAuthTokenError } from '_js/constants/Errors';

const authorizationCookieName = 'authorization-token';

export const defaultCookieOptions = {
  expires: 30,
};

export const getUrlToken = (urlQueryString) => {
  const queryObject = queryString.parse(urlQueryString);
  return queryObject.t || null;
};

export const setAuthorizationToken = (token, options = {}) => {
  Cookie.remove(authorizationCookieName, {
    domain: import.meta.env.VITE_CUSTOMER_PORTAL_URL.match(/https?:\/\/([^:]+)/)[1],
  });
  Cookie.set(authorizationCookieName, token, {
    ...options,
  });
};

export const getAuthorizationToken = () => Cookie.get(authorizationCookieName);

export const removeAuthorizationCookie = () => Cookie.remove(authorizationCookieName);

export const setAuthorizationCookie = (urlToken, options = {}) => {
  const cookieToken = getAuthorizationToken();
  if (!urlToken && !cookieToken) {
    throw new MissingAuthTokenError();
  }
  if (urlToken && cookieToken !== urlToken) {
    setAuthorizationToken(urlToken, options);
  }
};

export const getTokenPayloadRoles = (tokenPayload) => {
  if (tokenPayload.roles && Array.isArray(tokenPayload.roles)) {
    const roles = tokenPayload.roles.map((role) => ROLES[role.toUpperCase()]).filter(Boolean);

    if (roles.length > 0) {
      return roles;
    }
  } else if (tokenPayload.id) {
    return [ROLES.CUSTOMER];
  }

  return [ROLES.GUEST];
};

export const getPermissionsFromRoles = (userRoles) => {
  if (!userRoles || !Array.isArray(userRoles)) {
    return [];
  }

  return userRoles
    .map((role) => ROLE_PERMISSIONS[role])
    .filter(Boolean)
    .reduce((allUserPermissions, rolePermissions) => allUserPermissions.concat(rolePermissions), [])
    .filter(filterUnique);
};

export const hasPermissions = (requiredPermissions, authorizedPermissions) => {
  if (typeof requiredPermissions === 'string') {
    return authorizedPermissions.includes(requiredPermissions);
  }

  if (requiredPermissions instanceof Array && requiredPermissions.length > 0) {
    return requiredPermissions.every((requiredPermission) =>
      authorizedPermissions.includes(requiredPermission),
    );
  }

  return false;
};

export const getApplicationIdFromTokens = (tokenPayload) => {
  const urlAuthorizationToken = getUrlToken(window.location.search);
  if (urlAuthorizationToken) {
    try {
      const urlTokenPayload = decodeJwtPayload(urlAuthorizationToken);
      if (urlTokenPayload.id) {
        return Number(urlTokenPayload.id);
      }
    } catch (error) {
      if (!(error instanceof InvalidTokenError)) {
        Sentry.captureException(error);
      }
    }
  }

  if (tokenPayload && tokenPayload.id) {
    return Number(tokenPayload.id);
  }

  return null;
};
