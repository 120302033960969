// internal
import { API } from '_js/store/constants';

export const FETCH_CREDITORS = 'API/SYSTEM/FETCH_CREDITORS';
export const FETCH_CREDITORS_SUCCESS = 'API/SYSTEM/FETCH_CREDITORS_SUCCESS';
export const FETCH_CREDITORS_FAILED = 'API/SYSTEM/FETCH_CREDITORS_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to fetch creditors.',
  error,
});

export const fetchCreditors = () => ({
  type: API,
  types: [FETCH_CREDITORS, FETCH_CREDITORS_SUCCESS, FETCH_CREDITORS_FAILED],
  payload: () => ({
    url: '/creditors',
    success,
    failure,
    auth: true,
  }),
});
