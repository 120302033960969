const black = '#000000';
const brown = '#432400';
const orange = '#FF7000';
const white = '#ffffff';
const whitesmoke = '#f5f5f5';
const grey = '#c7c2c2';
const transparentOrange = '#FFE2CC';
const neutralGrey50 = '#f4f4f410';
const neutralGrey100 = '#E0E0E020';

export default {
  id: 'omalaina',
  loading: {
    background: whitesmoke,
    bounce: brown,
    fontColor: brown,
  },
  fonts: {
    button: {
      medium: '1.4em',
      large: '1.7em',
    },
    p: {
      small: '0.7500em',
      medium: '0.95em',
      mediumlarge: '1.1em',
      large: '1.4em',
    },
    headingsize: {
      small: '1.5em',
      medium: '2em',
      large: '2.5em',
    },
    subheading: {
      large: '2em',
    },
    bidlistfont: {
      size: '0.95em',
    },
    color: brown,
  },

  colors: {
    primary: orange,
    body: {
      background: whitesmoke,
    },
    button: {
      primary: {
        text: white,
        background: brown,
      },
      secondary: {
        text: white,
        background: orange,
      },
      tertiary: {
        text: white,
        bg: {
          default: 'transparent',
          hover: neutralGrey50,
          pressed: neutralGrey100,
        },
      },
    },
    navlink: {
      color: brown,
    },
    handlebutton: {
      color: {
        closed: orange,
        open: white,
      },
      bg: {
        closed: 'transparent',
        open: orange,
      },
    },
    circle: {
      color: brown,
      background: orange,
      boxShadow: orange,
    },
    carousel: {
      dot: orange,
      arrow: white,
    },
    expand: {
      background: orange,
    },
    checkbullet: {
      color: orange,
    },
    details: {
      color: orange,
    },
    numbers: {
      color: white,
    },
    arrow: {
      color: orange,
      chevron: white,
    },
    chevronArrow: {
      color: brown,
    },
    slider: {
      color: orange,
    },
    spinner: {
      color: orange,
    },
    border: {
      color: orange,
    },
    selectedOption: {
      color: orange,
    },
    acceptedBid: {
      border: orange,
      color: transparentOrange,
    },
    lucideIcons: {
      primary: orange,
      secondary: brown,
    },
    accordion: {
      background: white,
      border: brown,
      body: black,
      title: brown,
      chevron: brown,
    },
    infoCircle: {
      fill: orange,
    },
    myPagesAd: {
      background: whitesmoke,
      header: orange,
    },
  },
  styled: {
    button: orange,
    arrowButton: orange,
  },
  spinner: {
    border: brown,
  },
  header: {
    background: {
      color: white,
    },
  },
  bidPage: {
    separator: black,
  },
  breadcrumb: {
    background: {
      color: orange,
      ullet: white,
    },
    border: white,
    line: grey,
    checkIcon: orange,
    activeColor: black,
    fontColor: grey,
    bulletFontColor: white,
  },
  burgerMenuLi: {
    background: {
      color: white,
    },
  },
  headerText: {
    color: brown,
  },
  bidListBorder: {
    color: 'none',
    radius: '5px',
  },
  gjeldsregisteret: {
    privateLoans: {
      background: 'none',
      color: 'none',
    },
    creditCard: {
      background: 'none',
      color: 'none',
    },
    changeCards: {
      background: 'none',
      color: 'none',
    },
    button: {
      radius: '0px',
    },
  },
  insurance: {
    borderColor: brown,
    iconColor: orange,
    backgroundColor: white,
    textColor: brown,
  },
  acceptedLoanOfferPage: {
    statusSection: {
      backgroundColor: 'none',
      headerColor: 'none',
      content: 'none',
      button: {
        backgroundColor: 'none',
        color: 'none',
      },
    },
  },
};
