const LOGOS = {
  avida: 'avida-finans.svg',
  norwegian: 'bank-norwegian-no.svg',
  eika: 'eika.svg',
  express: 'express-bank.svg',
  facit: 'facit-bank.svg',
  ferratum: 'ferratum-logo-no.svg',
  'ikano-bank': 'ikano-bank.svg',
  ikano: 'ikano-bank.svg',
  instabank: 'instabank.svg',
  leabank: 'lea-bank.svg',
  komplett: 'se-morrow-bank.svg',
  nordax: 'nordax-bank.svg',
  nordea: 'nordea.svg',
  nstart: 'nstart.svg',
  remember: 'remember.svg',
  resurs: 'resurs-bank.svg',
  svea: 'svea-ekonomi.svg',
  tfbank: 'tfbank-no.svg',
  thorn: 'thorn.svg',
  ya: 'ya-resurs.svg',
};

export default LOGOS;
