const white = '#F4F4F4';
const primaryWhite = '##FFF';
const blue100 = '#EDF2FF';
const blue200 = '#BED0FF';
const blue400 = '#0D07EA';
const blue700 = '#000250';
const blue900 = '#000119';
const deepgray = '#525252';
const lightgray = '#e0e0e0';
const grey = '#A8A8A8';
const black = '#1E1E1E';
const neutralGrey50 = '#f4f4f410';
const neutralGrey100 = '#E0E0E020';

export default {
  id: 'digifinans',
  loading: {
    background: white,
    bounce: blue700,
    fontColor: deepgray,
  },
  fonts: {
    p: {
      small: '0.7500em',
      medium: '0.8750em',
      mediumSmall: '0.9375em',
      mediumlarge: '1em',
      large: '1.5em',
    },
    button: {
      medium: '1.125em',
      large: '1.125em',
    },
    headingsize: {
      small: '1.1em',
      medium: '1.5em',
      large: '2em',
    },
    subheading: {
      large: '1.5em',
    },
    bidlistfont: {
      size: '0.8750em',
    },
    color: white,
  },
  colors: {
    primary: blue700,
    button: {
      primary: {
        text: white,
        background: blue400,
      },
      secondary: {
        text: blue700,
        background: blue200,
      },
      tertiary: {
        text: white,
        bg: {
          default: 'transparent',
          hover: neutralGrey50,
          pressed: neutralGrey100,
        },
      },
    },
    navlink: {
      color: deepgray,
    },
    handlebutton: {
      color: {
        closed: blue400,
        open: white,
      },
      bg: {
        closed: 'transparent',
        open: 'transparent',
      },
    },
    carousel: {
      dot: blue400,
      arrow: blue200,
    },
    details: {
      color: blue700,
    },
    numbers: {
      color: blue400,
    },
    circle: {
      color: blue100,
      background: blue400,
      boxShadow: blue200,
    },
    expand: {
      background: blue700,
    },
    checkbullet: {
      color: blue700,
    },
    arrow: {
      color: blue200,
      chevron: blue400,
    },
    body: {
      background: primaryWhite,
    },
    chevronArrow: {
      color: blue400,
    },
    slider: {
      color: blue400,
    },
    border: {
      color: blue400,
    },
    spinner: {
      color: grey,
    },
    selectedOption: {
      color: blue400,
    },
    acceptedBid: {
      border: 'none',
      color: 'none',
    },
    lucideIcons: {
      primary: blue700,
      secondary: blue700,
    },
    accordion: {
      background: white,
      border: blue900,
      body: blue900,
      title: blue900,
      chevron: blue900,
    },
    infoCircle: {
      fill: blue700,
    },
    myPagesAd: {
      background: neutralGrey50,
      header: blue700,
    },
  },
  styled: {
    button: blue400,
    arrowButton: deepgray,
  },
  spinner: {
    border: blue200,
  },
  header: {
    background: {
      color: blue700,
    },
  },
  bidPage: {
    separator: 'transparent',
  },
  breadcrumb: {
    background: {
      color: blue700,
      bullet: blue200,
    },
    border: blue200,
    line: blue200,
    checkIcon: blue200,
    activeColor: blue200,
    fontColor: blue200,
    bulletFontColor: blue200,
  },
  burgerMenuLi: {
    background: {
      color: blue700,
    },
  },
  headerText: {
    color: blue700,
  },
  bidListBorder: {
    color: lightgray,
    radius: '10px',
  },
  gjeldsregisteret: {
    privateLoans: {
      background: blue400,
      color: white,
    },
    creditCard: {
      background: blue700,
      color: white,
    },
    changeCards: {
      background: blue100,
      color: black,
    },
    button: {
      radius: '63px',
    },
  },
  insurance: {
    borderColor: 'none',
    iconColor: 'none',
    backgroundColor: 'none',
  },
  acceptedLoanOfferPage: {
    statusSection: {
      backgroundColor: 'none',
      headerColor: 'none',
      content: 'none',
      button: {
        backgroundColor: 'none',
        color: 'none',
      },
    },
  },
};
