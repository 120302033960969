// internal
import { API } from '_js/store/constants';

export const REQUEST_NEW_BIDS = 'API/SYSTEM/REQUEST_NEW_BIDS';
export const REQUEST_NEW_BIDS_SUCCESS = 'API/SYSTEM/REQUEST_NEW_BIDS_SUCCESS';
export const REQUEST_NEW_BIDS_FAILED = 'API/SYSTEM/REQUEST_NEW_BIDS_FAILED';

const requestNewBidsSuccess = (type, payload) => ({
  type,
  payload,
});

const requestNewBidsFailed = (type, error) => ({
  type,
  error,
});

export const requestNewBids = ({ applicationId, creditors, requiresUcCheck }) => ({
  type: API,
  types: [REQUEST_NEW_BIDS, REQUEST_NEW_BIDS_SUCCESS, REQUEST_NEW_BIDS_FAILED],
  method: 'post',
  payload: () => ({
    url: `/applications/${applicationId}/bidding/requestNewResponses`,
    params: {
      id: applicationId,
      creditorIds: creditors,
      creditCheckOk: requiresUcCheck,
    },
    success: requestNewBidsSuccess,
    failure: requestNewBidsFailed,
    auth: true,
    throwOnError: true,
  }),
});
