import { ppiSigningStatus, startPpiSigning, startPpiSigningWithoutBankID } from '_js/utils/api/API';
import { loginSign } from '_js/store/actions/api/auth/login';
import Poller from '_js/utils/PollerAsync';
import { MINIMUM_BIDDING_POLLING_INTERVAL } from '_js/constants/Time';
import { getLoginType } from '_js/brands';
import Cookie from 'js-cookie';

// internal
import { API } from '_js/constants/api';
import { getInsuranceEligibility } from './fetch';

export const SIGN_PPI = 'API/SYSTEM/SIGN_PPI';
export const SIGN_PPI_SUCCESS = 'API/SYSTEM/SIGN_PPI_SUCCESS';
export const SIGN_PPI_FAILED = 'API/SYSTEM/SIGN_PPI_FAILED';
export const SIGN_PPI_RESET_ERROR_IF_HAPPENED = 'API/SYSTEM/SIGN_PPI_RESET_ERROR_IF_HAPPENED';
export const SIGN_PPI_STOP = 'API/SYSTEM/SIGN_PPI_STOP';
export const SIGN_PPI_PAPER_INVOICE = 'API/SYSTEM/SIGN_PPI_PAPER_INVOICE';
export const SIGN_PPI_PAPER_INVOICE_SUCCESS = 'API/SYSTEM/SIGN_PPI_PAPER_INVOICE_SUCCESS';
export const SIGN_PPI_PAPER_INVOICE_FAILED = 'API/SYSTEM/SIGN_PPI_PAPER_INVOICE_FAILED';
export const SIGN_PPI_AUTO_GIRO = 'API/SYSTEM/SIGN_PPI_AUTO_GIRO';
export const SIGN_PPI_AUTO_GIRO_SUCCESS = 'API/SYSTEM/SIGN_PPI_AUTO_GIRO_SUCCESS';
export const SIGN_PPI_AUTO_GIRO_FAILED = 'API/SYSTEM/SIGN_PPI_AUTO_GIRO_FAILED';
export const UPDATE_QR_DATA = 'API/SYSTEM/UPDATE_QR_DATA';

const BANK_ID_RESPONSES = {
  FAILED: 'failed',
  NOT_FOUND: 'notFound',
  COMPLETED: 'completed',
  STARTED: 'started',
};

let poller;

const statusInProgressQR = (qrData = null, qrRestart = false) => ({
  type: UPDATE_QR_DATA,
  payload: {
    qrData,
    qrRestart,
  },
});

export const cancelPPIQR = () => ({
  type: UPDATE_QR_DATA,
  payload: {
    qrData: null,
  },
});

const statusInProgress = (autoStartToken) => ({
  type: SIGN_PPI,
  payload: {
    autoStartToken,
  },
});

const statusDone = ({ status }) => ({
  type: SIGN_PPI_SUCCESS,
  payload: {
    status,
  },
});

const statusFailed = () => ({
  // TODO: show message to user or log it
  type: SIGN_PPI_FAILED,
});

export const statusStop = () => {
  if (poller) {
    poller.stopPolling();
  }
  return {
    type: SIGN_PPI_STOP,
  };
};

export const ppiResetError = () => ({
  type: SIGN_PPI_RESET_ERROR_IF_HAPPENED,
});

const callback = (token, dispatch, applicationId, selectedPpiLevel, selectedPpiAmount, options) =>
  ppiSigningStatus(token)
    .then((response = {}) => {
      if (!response) {
        return;
      }
      dispatch(statusInProgressQR(response.qrData, response.restart));
      if (response.status === BANK_ID_RESPONSES.FAILED) {
        poller.stopPolling();
        dispatch(statusStop());
      } else if (response.status === BANK_ID_RESPONSES.COMPLETED) {
        poller.stopPolling();
        if (response.insurance) {
          dispatch(getInsuranceEligibility({ applicationId }));
          dispatch(
            statusDone({
              selectedPpiLevel,
              selectedPpiAmount,
              options,
              status: response.insurance.status,
            }),
          );
        }
      } else if (response.status === BANK_ID_RESPONSES.NOT_FOUND) {
        throw new Error('Bank ID response - not found');
      }
    })
    .catch(() => {
      poller.stopPolling();
      dispatch(statusFailed());
    });

const startPollingForStatus = ({
  applicationId,
  selectedPpiLevel,
  selectedPpiAmount,
  options,
  token,
  dispatch,
  autoStartToken,
}) => {
  dispatch(statusInProgress(autoStartToken));

  poller = new Poller(
    () => callback(token, dispatch, applicationId, selectedPpiLevel, selectedPpiAmount, options),
    MINIMUM_BIDDING_POLLING_INTERVAL,
  );
  poller.startPolling(true);
};

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  error,
});

// eslint-disable-next-line import/prefer-default-export
export const ppiPaymentMethod = (applicationId, bankAccount, paymentMethod) => ({
  type: API,
  types: [SIGN_PPI_AUTO_GIRO, SIGN_PPI_AUTO_GIRO_SUCCESS, SIGN_PPI_AUTO_GIRO_FAILED],
  method: 'put',
  payload: () => ({
    url: `/applications/${applicationId}/insurance/paymentMethod`,
    params: {
      gateway: paymentMethod,
      data: { bankAccount },
    },
    success,
    failure,
    throwOnError: true,
    auth: true,
  }),
});

export const signFiPpi = (isAutogiro, skipLetters, signStatus) => (dispatch, getState) => {
  const {
    application: {
      id: applicationId,
      bankAccount,
      applicantDraft: { nationalId },
    },
    ppi: { options },
  } = getState();
  const selectedOption =
    options.find((option) => option.selected) || options[Math.round(options.length / 2) - 1];
  const selectedPpiLevel = selectedOption.id || selectedOption.amount;

  const type = getLoginType();
  const selectedOptionId = selectedOption.id;
  const gateway = 'postnordStralfors';

  if (signStatus === 'completed') {
    const hasSelectedSkipLettersBeforeSigning = Cookie.get('skipLetters');
    const selectedInsuranceLevelBeforeSigning = Cookie.get('selectedInsurance');

    startPpiSigningWithoutBankID(
      applicationId,
      selectedInsuranceLevelBeforeSigning,
      isAutogiro,
      hasSelectedSkipLettersBeforeSigning,
      bankAccount,
      'postnordStralfors',
    )
      .then(() => {
        dispatch(
          statusDone({
            selectedInsuranceLevelBeforeSigning,
            options,
            status: 'existingPPI',
          }),
        );
      })
      .catch((error) => {
        dispatch(statusFailed(error.message));
      })
      .finally(() => {
        Cookie.remove('selectedInsurance');
        Cookie.remove('skipLetters');
      });
  }

  if (signStatus === 'notFound') {
    Cookie.set('selectedInsurance', selectedPpiLevel);
    Cookie.set('skipLetters', skipLetters);
    dispatch(
      loginSign({
        applicationId,
        bankAccount,
        nationalId,
        type,
        selectedOptionId,
        isAutogiro,
        skipLetters,
        gateway,
        selectedPpiLevel,
      }),
    );
  }
};

export const signPpi = (isAutogiro, skipLetters) => (dispatch, getState) => {
  const {
    application: { id: applicationId },
    ppi: { options },
  } = getState();
  const selectedOption =
    options.find((option) => option.selected) || options[Math.round(options.length / 2) - 1];
  const selectedPpiLevel = selectedOption.id || selectedOption.amount;
  return startPpiSigning(applicationId, selectedOption.id, isAutogiro, skipLetters)
    .then((response) =>
      startPollingForStatus({
        applicationId,
        selectedPpiLevel,
        selectedPpiAmount: selectedOption.amount,
        options,
        token: response?.token,
        dispatch,
        autoStartToken: response?.autoStartToken,
      }),
    )
    .catch((error) => {
      dispatch(statusFailed(error.message));
    });
};
