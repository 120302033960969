import { Suspense } from 'react';
import styled, { css } from 'styled-components';

import LanemarknadLogo from '../../images/lanemarknad-logo.svg?react';
import SamblaLogo from '../../images/sambla-logo.svg?react';
import AdvisaLogo from '../../images/advisa-logo.svg?react';
import AdvisaSamlaLan from '../../images/advisa-samla-lan.svg?react';
import SamblaSamlaLan from '../../images/sambla-samla-lan.svg?react';
import OmalainaLogo from '../../images/omalaina-logo.svg?react';
import RahoituLogo from '../../images/rahoitu-logo.svg?react';
import RahalaitosLogo from '../../images/rahalaitos-white.svg?react';
import DigifinansLogo from '../../images/digifinans-logotype.svg?react';
import Spinner from '../components/atoms/Spinner';

const device = {
  mobile: '(min-width : 0rem) and (max-width : 32rem)',
  desktop: '(min-width : 114rem)',
  laptop: '(min-width: 62rem)',
};

const imageStyles = css`
  width: 495px;
  height: 400px;
  padding-right: 0.4em;

  @media ${device.mobile} {
    width: 110%;
    height: 305px;
  }

  @media ${device.desktop} {
    width: 600px;
    height: 595px;
  }
`;

const StyledOmalaina = styled(OmalainaLogo)`
  width: auto;
  height: 4em;

  @media ${device.mobile} {
    width: 5.5em;
    height: 2em;
    margin-left: 0.5em;
  }
`;
const StyledAdvisaSamlaLan = styled(AdvisaSamlaLan)`
  ${imageStyles}
`;

const StyledSamblaSamlaLan = styled(SamblaSamlaLan)`
  ${imageStyles}
`;

const StyledRahoituLogo = styled(RahoituLogo)`
  width: 110px;

  @media ${device.laptop} {
    width: 190px;
  }
`;

const StyledRahalaitosLogo = styled(RahalaitosLogo)`
  height: 90%;
  width: 195px;
`;

const StyledDigifinansLogo = styled(DigifinansLogo)`
  height: 90%;
  width: 170px;
`;

const StyledAdvisaLogo = styled(AdvisaLogo)`
  width: 180px;
`;

const Advisa = <StyledAdvisaLogo />;

const Sambla = (
  <SamblaLogo
    className="
o-advisa-logo
u-margin--left-half-em@mobile
u-margin--right-half-em@mobile"
  />
);

const Lanemarknad = (
  // eslint-disable-next-line formatjs/no-literal-string-in-jsx
  <a href="https://lanemarknad.se/" aria-label="Lånemarknad">
    <LanemarknadLogo
      className="
      o-lanemarknad-logo
      o-lanemarknad-logo--blue
      u-margin--left-half-em@mobile
      u-margin--right-half-em@mobile
      "
    />
  </a>
);

const Omalaina = <StyledOmalaina />;
const Rahoitu = <StyledRahoituLogo />;
const Rahalaitos = <StyledRahalaitosLogo />;
const Digifinans = <StyledDigifinansLogo />;
const AdvisaQandA = (
  <Suspense fallback={<Spinner classes={['c-spinner--white@desktop']} />}>
    <StyledAdvisaSamlaLan />
  </Suspense>
);

const SamblaQandA = (
  <Suspense fallback={<Spinner classes={['c-spinner--white@desktop']} />}>
    <StyledSamblaSamlaLan />
  </Suspense>
);

export {
  Advisa,
  Sambla,
  Lanemarknad,
  Omalaina,
  AdvisaQandA,
  SamblaQandA,
  Rahoitu,
  Rahalaitos,
  Digifinans,
};
