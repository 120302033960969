/* eslint-disable import/no-extraneous-dependencies, no-console */
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { getEnvironment, isDevelopment, isStaging } from '_js/utils/environment';

// eslint-disable-next-line import/prefer-default-export
export const initLogging = (version) => {
  Sentry.init({
    dsn: 'https://c842083bec9849278465a61dec76ff02@o451393.ingest.sentry.io/5437208',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: isStaging() ? 1 : 0.05,
    environment: getEnvironment(),
    release: version,
    normalizeDepth: 6,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this.
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit',
    ],
    denyUrls: [
      // Imbox chat
      /files\.imbox\.io/i,
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    beforeSend: (event, hint) => {
      if (isDevelopment()) {
        // React errors will already be logged by react-error-overlay in development
        if (!event?.contexts?.react) {
          console.error(hint.originalException || hint.syntheticException);
        }

        if (!import.meta.env.VITE_SENTRY) {
          console.info('SENTRY DISABLED: Captured error not sent to Sentry:', event);

          // Do not send the error to Sentry in development unless VITE_SENTRY is set
          return null;
        }
      }
      return event;
    },
  });
  Sentry.setTag('hostname', window.location.hostname);
};
