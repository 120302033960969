/* eslint-disable quote-props */

// Sorted in alphabetic order
const LOGOS = {
  aktiabank: 'aktiabank.svg',
  aurajoki_laina_consumer: 'laina.svg',
  aurajoki_laina_limitaccount: 'laina.svg',
  aurajoki_risicum_consumer: 'risicum.svg',
  aurajoki_risicum_limitaccount: 'risicum.svg',
  avida: 'avida-finans.svg',
  balanzia: 'balanzia.svg',
  banknorwegian: 'bank-norwegian-fi.svg',
  bigbank: 'big-bank.svg',
  bondora: 'bondora.svg',
  brabank: 'lea-bank.svg',
  brocc: 'brocc.svg',
  credento: 'tfbank.svg',
  credigo: 'credigo.svg',
  credit24: 'credit24.svg',
  easycredit: 'easycredit.svg',
  euroloan: 'euroloan.svg',
  'alisa-pankki': 'alisa-pankki.svg',
  ferratum: 'ferratum.svg',
  finrefin: 'tfbank.svg',
  gfmoney: 'gfmoney.svg',
  halens: 'halino.svg',
  instabank: 'instabank.svg',
  joustoluotto: 'northmill-bank.svg',
  kaarna: 'kaarna.svg',
  'morrow-private-loan': 'fi-morrow-bank.svg',
  'morrow-credit-card': 'fi-morrow-bank.svg',
  lainaamo: 'lainaamo.svg',
  leabank: 'lea-bank.svg',
  mylender: 'mylender.svg',
  nordax: 'nordax-bank.svg',
  pop: 'pop.svg',
  remember: 'remember.svg',
  resursbank: 'resurs-bank.svg',
  'saldo-kulutusluotto': 'saldo-bank.svg',
  santander: 'santander.svg',
  svea: 'svea-ekonomi.svg',
  'sweep-credit': 'sweep.svg',
  'tf-bank': 'tfbank.svg',
  'saldo-joustoluotto': 'saldo-bank.svg',
};

export default LOGOS;
