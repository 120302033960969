export default [
  'Aasa Kredit',
  'Agrol',
  'American Ex',
  'Apoteket',
  'Audi',
  'Avida Finans',
  'BMW',
  'BRABANK',
  'BYAX',
  'Balanzia',
  'Bank Norwegian',
  'Bauhaus',
  'Big Bank',
  'Bilia',
  'Bilpant',
  'Bluecard',
  'Bluestep',
  'Bravio',
  'Brixo private loan',
  'Brocc',
  'Byggmax',
  'Cash2you',
  'Cashbuddy',
  'Cellbes',
  'Citibank',
  'Collector',
  'Coop',
  'Credento',
  'Credigo',
  'Creditum',
  'Credway',
  'Northmill',
  'DNB',
  'Danske Bank',
  'Daypay',
  'Diners club',
  'Easycredit',
  'Ecster',
  'Elgiganten',
  'Ellos',
  'Engelsons',
  'Entercard',
  'Eurocard',
  'Euroloan',
  'Everyday Plus',
  'Everydaycard',
  'Exchange',
  'Expert',
  'Expresskredit',
  'Fellow Finance',
  'Ferratum',
  'Finaref',
  'Folkia',
  'Forex',
  'Frogtail',
  'Fyrklövern',
  'Försäkringskassan',
  'GE Moneybank',
  'Goodcash',
  'H&M',
  'Halens',
  'Handelsbanken',
  'Hedin',
  'Hemköp',
  'Hemtex',
  'ICA Banken',
  'IF metall',
  'Ikanobanken',
  'Ikea',
  'Ingo',
  'Instabank',
  'Jotex',
  'Jula',
  'Klarna',
  'Kia',
  'Klicklån',
  'Kommunal',
  'Komplett Bank',
  'Konsumentkredit',
  'Kredit365',
  'Kundfinans',
  "L'EASY",
  'La Redoute',
  'Lea Bank',
  'Lunar',
  'Lindex',
  'Lindorff',
  'Länsförsäkringar',
  'Lärarnas',
  'Lån & Spar Bank',
  'Lån för alla',
  'Lånbolaget',
  'Lånbutiken',
  'Marginalen',
  'MedMera Bank',
  'Meddelandelån',
  'MediaMarkt',
  'Mekonomen',
  'Mercedes',
  'Moank',
  'Monetti',
  'Moneygo',
  'Moneypal',
  'Monobank',
  'More Golf',
  'MyMoney',
  'Nanoflex',
  'NetOnNet',
  'Nettofinans',
  'Nordax',
  'Nordea',
  'Nordnet',
  'Norwegian',
  'Nstart',
  'Nätlån',
  'OKQ8',
  'OnOff',
  'Onea',
  'Paxo',
  'Payex',
  'Pengos',
  'Peugeot',
  'Pluslån',
  'Preem',
  'Qliro',
  'Re:member',
  'Resurs',
  'Risicum',
  'SAS Eurobonus',
  'SBAB',
  'SEB',
  'SJ',
  'SMS-lån',
  'ST1',
  'Saldo',
  'Salus Ansvar',
  'Santander',
  'Savelend',
  'Selene Finans',
  'SevenDay Bank',
  'Shell',
  'Siba',
  'Sjöräddning',
  'Skandiabanken',
  'Smspengar',
  'Snabbfinans',
  'Sparbanken Öresund',
  'Spring Finance',
  'Spendwise',
  'Stadium',
  'Statoil',
  'Strandia Finans',
  'SupremeCard',
  'Svea Ekonomi',
  'Swedbank',
  'Säker Finans',
  'TF Bank',
  'Thorn',
  'Time finans',
  'Toyota',
  'Trustbuddy',
  'Ullared',
  'Viaconto',
  'Vivus',
  'Volkswagen Finans',
  'Volvo',
  'Wasa Kredit',
];
