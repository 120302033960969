const LOGOS = {
  'avida-finans-lan': 'avida-finans.svg',
  'avida-finans': 'avida-finans.svg',
  balanzia: 'balanzia.svg',
  'bank-norwegian': 'bank-norwegian-se.svg',
  banky: 'banky.svg',
  bigbank: 'big-bank.svg',
  brabank: 'brabank.svg',
  brixo: 'brixo.svg',
  'brixo-privateloan': 'brixo.svg',
  brocc: 'brocc.svg',
  byax: 'byax.svg',
  cashbuddy: 'cashbuddy.svg',
  collector: 'collector.svg',
  credento: 'credento.svg',
  credigo: 'credigo.svg',
  credway: 'credway.svg',
  equilo: 'equilo.svg',
  euroloan: 'euroloan.svg',
  facit: 'facit.svg',
  'fellow-finance': 'fellow-finance.svg',
  ferratum: 'ferratum.svg',
  'forex-bank': 'forex-bank.svg',
  friia: 'friia.svg',
  'ge-money-bank': 'ge-money-bank.svg',
  goodcash: 'goodcash.svg',
  'ica-banken': 'ica-banken.svg',
  'ikano-bank': 'ikano-bank.svg',
  ikano: 'ikano-bank.svg',
  instabank: 'instabank.svg',
  joustoluotto: 'northmill-bank.svg',
  konsumentkredit: 'konsumentkredit.svg',
  'lea-bank': 'lea-bank.svg',
  loanstep: 'loanstep.svg',
  lunar: 'lunar.svg',
  'marginalen-bank': 'marginalen-bank.svg',
  'medmera-bank': 'medmera-bank.svg',
  moank: 'moank.svg',
  moneypal: 'moneypal.svg',
  monobank: 'monobank.svg',
  'morrow-bank': 'se-morrow-bank.svg',
  nordax: 'nordax-bank.svg',
  nordea: 'nordea.svg',
  'nordnet-bank': 'nordnet-bank.svg',
  'northmill-kontokredit': 'northmill-bank.svg',
  'northmill-pafyllning': 'northmill-bank.svg',
  nstart: 'nstart.svg',
  'primal-capital': 'primal-capital.svg',
  rebilla: 'rebilla.svg',
  remember: 'remember.svg',
  'resurs-bank': 'resurs-bank.svg',
  risicum: 'risicum.svg',
  saldo: 'saldo-bank.svg',
  seb: 'seb.svg',
  'sevenday-finans': 'sevenday-finans.svg',
  sparlan: 'brocc.svg',
  'spring-finance': 'bluestep.svg',
  'sveadirekt-konsument': 'svea-ekonomi.svg',
  'sveadirekt-privat': 'svea-ekonomi.svg',
  swedbank: 'swedbank.svg',
  spendwise: 'spendwise.svg',
  tfbank: 'tfbank.svg',
  thorn: 'thorn.svg',
  tryggkredit: 'trygg-credit.svg',
  villabanken: 'lan-spar-bank.svg',
  'wasa-kredit': 'wasa-kredit.svg',
  'wastgota-finans': 'wastgota-finans.svg',
};

export default LOGOS;
