// internal
import { API } from '_js/store/constants';

export const UPDATE_APPLICATION = 'API/SYSTEM/UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'API/SYSTEM/UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILED = 'API/SYSTEM/UPDATE_APPLICATION_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  error,
});

export const updateApplication = (
  id,
  { newLoanAmount, totalAmount, repaymentTime, loanPurpose, bankAccount },
) => {
  const params = {
    newLoan: newLoanAmount,
    totalAmount,
    repaymentTime: repaymentTime * 12,
    purpose: loanPurpose,
  };
  if (bankAccount) {
    params.bankAccount = bankAccount;
  }
  return {
    type: API,
    types: [UPDATE_APPLICATION, UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION_FAILED],
    method: 'post',
    payload: () => ({
      url: `/applications/${id}/update`,
      params,
      success,
      failure,
      throwOnError: true,
      auth: true,
    }),
  };
};
